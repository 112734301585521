import { render, staticRenderFns } from "./MonsterUpdate.vue?vue&type=template&id=335fa528&scoped=true"
import script from "./MonsterUpdate.vue?vue&type=script&lang=js"
export * from "./MonsterUpdate.vue?vue&type=script&lang=js"
import style0 from "./MonsterUpdate.vue?vue&type=style&index=0&id=335fa528&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335fa528",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSelect});
